.rounded-btn {
  background-color: transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  border: 1px solid #1bc0e8;
  color: #1bc0e8;
  cursor: pointer;
  transition: all 0.1s ease 0s;
  font-size: 14px;
  background-image: linear-gradient(to top, #1bc0e8 50%, transparent 50%);
  background-size: 100% 200%;
  background-position: top;
  padding: 0rem 2.5rem;
  transition: background-position 0.5s ease-in-out; /** I've changed the time for demo purposes **/
}

.rounded-btn:hover {
  background-color: transparent;
  /* color: #00aeef !important; */
  color: #121212;
  background-position: bottom;
}

.brand {
  width: 150px;
  margin-left: 3rem;
}
.header {
  /* height: 14vh !important; */
  width: 100%;
  padding: 0;
  color: white;
  background: #151414;
  z-index: 10000;
  border-bottom: 1px solid white;
}

.navbar > .container {
  margin: 0;
}

.navbar .container {
  max-width: 100%;
}
.navbar-collapse {
  flex-grow: inherit;
}

.header-item {
  text-align: center;
  margin-left: 2rem;
  color: white;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 57px;
  transition: all 0.3s ease;
}

.header-item::before {
  transition: 300ms;
  height: 2px;
  content: "";
  position: absolute;
  width: 0%;
  background-color: #1bc0e8;
}

.nav-link-ltr::before {
  width: 0%;
  bottom: 25px;
}

.nav-link-ltr:hover::before {
  width: 80%;
}
.nav-link-ltr-special::before {
  width: 0%;
  bottom: 18px;
}
.navbar-nav .dropdown-menu {
  margin: -12px;
}
.nav-item .dropdown-menu {
  background: #121212;
}

.nav-item .dropdown-item {
  color: #ffffff;
}

.nav-item .dropdown-item:hover {
  background: #00aeef;
}

.header-item-selected {
  color: #00aeef !important;
}
.header-item-selected::before {
  color: #00aeef;
  width: 80%;
  /* border-bottom: 1px solid rgb(241, 59, 144); */
}

.header-item:hover {
  color: #00aeef;
  /* border-bottom: 1px solid rgb(241, 59, 144); */
}

.get-in-touch {
  font-size: 12px;
  opacity: 0;
}

.navbar-brand img {
  width: 10rem !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .get-in-touch {
    display: none;
  }
  .header-item::before {
    background-color: transparent;
  }
  .brand {
    margin-left: auto;
    margin-right: auto;
  }
  .container img {
    margin: 1rem 0 !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .get-in-touch {
    display: none;
  }
  .header-item::before {
    background-color: transparent;
  }
  .brand {
    margin-left: auto;
    margin-right: auto;
  }
  .container img {
    margin: 1rem 0 !important;
  }
  /* .header {
    height: 10vh !important;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .get-in-touch {
    display: contents;
    margin-left: auto;
    opacity: 0;
  }

  /* .get-in-touch:hover {
    cursor: none;
  } */
  .me-auto {
    margin-right: 0;
  }
  .brand {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .header-item::before {
    background-color: #00aeef;
  }
  .container img {
    margin-bottom: 1rem !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .get-in-touch {
    display: contents;
    margin-left: auto;
    opacity: 0;
  }
  .brand {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .me-auto {
    margin-right: 0;
  }
  .header-item::before {
    background-color: #00aeef;
  }
  .container img {
    margin-bottom: 1rem !important;
  }
}

ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  margin-top: 2rem;
  background: #1b1b1b;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  width: 10rem;
}

.footer-text li {
  margin-bottom: 14px;
  font-size: 14px;
  color: white;
  line-height: 28px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: white;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 15px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.linkedin-bg {
  color: white;
  background: #3b5998;
}
.youtube-bg {
  background: #c00;
}
.instagram-bg {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
}

.footer-widget ul li {
  display: block;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li:hover {
  color: #fb1e8d;
  cursor: pointer;
}
.footer-widget ul li a {
  color: white;
  text-decoration: none;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  text-align: center;
  background: #202020;
  padding: 25px 0;
  margin: 0 auto;
}
.copyright-text p {
  text-align: center;
  font-size: 14px;
  color: #878787;
}
.copyright-text span {
  font-weight: 300;
  text-decoration: none;
  margin-left: 5px;

  color: #fb1e8d;
}

.copyright-text span:hover {
  cursor: pointer;
}

.copyright-area li {
  display: inline-block;
  margin-right: 5px;
}
.copyright-area li:hover {
  cursor: pointer;
}
.footer-menu {
  display: block;
  margin-top: 0.2rem;
}
.divider-footer {
  display: inline-block;
  border-right: 1px solid white;
  height: 15px;
}
.footer-menu li {
  display: inline-block;
}
.footer-menu li:hover a {
  color: #fb1e8d;
}
.footer-menu li a {
  color: white;
  margin-left: 4px;
  margin-right: 3px;
  text-decoration: none;
  font-size: 14px;
}

.footer-text a {
  text-decoration: none;
  color: white;
}

.footer-text a:hover {
  color: #fb1e8d;
}
.address-grid {
  display: grid;
  grid-template-columns: auto auto;
}

.grid-address-item {
  color: white;
  margin-bottom: 20px;
  text-align: left;
  font-size: 14px;
  padding-right: 20px;
}

.footer-social-icon i {
  color: white;
}

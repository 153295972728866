.form-background {
  text-align: left;
}
.form-background {
  background: #121212 !important;
  /* box-shadow: 3px 3px 25px white; */
}

.form-label {
  color: white;
}

.form-control {
  color: white;
  background-color: #262626;
  border-radius: 0px;
  border-color: 1px solid #a3a3a3;
}

.form-group {
  margin-right: 0.5rem;
  width: -webkit-fill-available;
  margin-bottom: 1rem;
  text-align: left;
}

.one-container {
  display: flex;
  justify-content: center;
}
.required:after {
  content: " *";
  color: red;
}
input[type="text"]:focus {
  background-color: #262626;
  color: white;
  outline: none; /* Remove default outline and use border or box-shadow */
}

input[type="email"]:focus {
  background-color: #262626;
  color: white;
  outline: none; /* Remove default outline and use border or box-shadow */
}

select,
textarea {
  background-color: #262626;
  margin-bottom: 1rem;
  text-align: left;
}

.cross-btn {
  font-size: 2rem;
}

.modal.show .modal-dialog {
  display: flex;
  transform: none;
  align-items: center;
  justify-content: center;
  height: -webkit-fill-available;
  max-width: 50vw !important;
}

.modal-content {
  /* height: 50vh; */
  height: 50vh;
}

.form-heading {
  text-align: center;
  justify-content: space-around;
  position: relative;
}

.form-heading i {
  position: absolute;
  right: 0;
  top: 0;
}
.form-heading i:hover {
  cursor: pointer;
}

.form-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modal-content {
    height: 80% !important;
  }
  .modal.show .modal-dialog {
    max-width: 100vw !important;
  }
  .one-container {
    display: block;
  }
  .modal {
    top: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .modal-content {
    height: 100% !important;
  }
  .modal.show .modal-dialog {
    max-width: 100vw !important;
  }
  .one-container {
    display: block;
  }
  .modal {
    top: 0;
  }

  .container-fluid {
    margin-top: 2rem !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .one-container {
    display: flex;
  }
  .modal-content {
    height: 50vh !important;
  }
  .modal {
    top: -10% !important;
  }

  .container-fluid {
    margin-top: 0 !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .one-container {
    display: flex;
  }
  .modal.show .modal-dialog {
    display: flex;
    transform: none;
    align-items: center;
    justify-content: center;
    height: -webkit-fill-available;
    max-width: 50vw !important;
  }
  .modal-content {
    height: 50vh !important;
  }
  .modal {
    top: -10% !important;
  }

  .container-fluid {
    margin-top: 0 !important;
  }
}
